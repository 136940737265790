.blue {
    color: #044dac;
  }

 
/* Ensure all text elements are left-aligned */
.services-section h1,
.services-section h4,
.services-section p,
.tenant-section h1,
.tenant-section p {
  text-align: left !important;
}
  
  .services-section,
  .tenant-section {
    text-align: left;
    padding: 20px;
  }
  
  /* Apply styles to all paragraphs within sections */
  .services-section p,
  .tenant-section p {
    margin-bottom: 15px;
    line-height: 1.6;
  }
  
  /* General Heading Styling */
  h1, h4 {
    text-align: left;
  }
  
  .fw-light {
    font-weight: 300;
  }
  