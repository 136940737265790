/*  landlords start   */

.landlords-cHeader {
    font-size: 36px;
    font-weight: 700;
}

.landlords-cHeader {
    font-weight: 400 !important;
    color: rgb(44, 44, 44);
}

.landlords-cPara {
    font-size: 18px;
    margin-top: 28px;
    font-weight: 480;
    width: 85%;
    text-align: left;
}

.landlords-cPara a {
    text-decoration: none;
    margin: 0px 0px 0px 8px;
    color: #007bff;
}

.landlords-cPara a:hover {
    text-decoration: underline;
    color: #1766bb;
}

.landlords-cContacth {
    font-weight: 700;
    font-size: 18px;
    color: rgb(54, 54, 54);
    margin-top: 20px;
}

.landlords-cContacth2 a {
    color: #007bff;
    text-decoration: none;
}

.landlords-cContacth2 a:hover {
    text-decoration: underline;
    color: #1766bb;
}

/* .landlords-form {
    margin-left: 8%;
} */

.landlord-form-main {
    margin-top: 50px;
}

.landlords-input {
    display: grid;
    grid-template-columns: 20% 65%;
    column-gap: 30px;
}

.landlords-form-select {
    width: 100%;
    height: 36px !important;
}

.Property-Currently-radio {
    display: flex;
    flex-wrap: wrap;
}

.landlords-form-label {
    font-size: 20px;
    font-weight: 500;
    color: rgba(51, 51, 51, 0.9);
    display: block;
    text-align: right;
}
.landlords-form-label2 {
    font-size: 20px;
    font-weight: 500;
    color: rgba(51, 51, 51, 0.9);
    display: block;
    text-align: start;
}


.Property-Currently2 {
    font-size: 18px;
    font-weight: 500;
}

.Property-Currently-textarea {
    width: 100%;
    height: 180px;
}

.Property-Currently-button {
    padding: 6px 40px;
    border-radius: 4px;
    border: none;
    background-color: #007bff;
    color: #fff;
}

.Property-Currently-button:hover {
    color: #fff;
    background-color: #0361c5;
    transition: 0.9s;
}
.capthaBox-container{
    justify-content: center;
}
.capthaBox{
    justify-content: center;
    display: grid;
    
}
.captchaText{
    margin: 10px 0;
}

/*******  landlords media screen start   ********/

@media screen and (max-width: 800px) {
    .landlords-cPara {
        width: 100%;
        text-align: left;
    }
    .landlords-input {
        grid-template-columns: 25% 75%;
    }
}



@media screen and (max-width: 550px) {

    .container {
        /* margin-top: 30px !important; */
    }

    .landlords-main-head {
        width: 100%;
        padding: 5px;
    }

    .landlords-cHeader {
        font-size: 18px !important;
        font-weight: bolder !important;
    }

    .landlords-cPara {
        width: 100%;
        font-size: 15px;
        justify-content: center;
        text-align: left;
    }

    .landlords-cContacth1 {
        font-size: 15px;
    }

    .landlords-cContacth2 {
        font-size: 15px;
    }

    .landlords-form {
        margin: 0px;
        padding: 5px;
    }

    .landlords-input {
        display: grid;
        grid-template-columns: auto;
    }

    .dummyhello {
        display: none;
    }

    .landlords-form-label {
        font-size: 16px;
        text-align: left;
        margin-bottom: 10px;
    }
    .landlords-form-label2 {
        font-size: 16px;
    }

    .Property-Currently-radio {
        display: grid;
        grid-template-columns: 100%;
    }

    .Property-Currently2 {
        font-size: 15px;
    }
}